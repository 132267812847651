import axios from "../config/axios";

const TerminalService = {

    fetchDisconnectedTerminals: async () => {
        try {
            const response = await axios.get(`/terminals/disconnected-terminals`);
            return response.data;
        } catch (error){
            throw error;
        }
    }
};

export default TerminalService;