export function getColumns (t) {
    return [
        {
            name: t('billing.notifications.tableHeader.enterprise'),
            selector: row => row.parentEntityName,
            sortable: true,
        },
        {
            name: t('billing.notifications.tableHeader.entity'),
            selector: row => row.entityName,
            sortable: true,
        },
        {
            name: t('billing.notifications.tableHeader.qty'),
            selector: row => row.counter,
            sortable: true,
            right: true,
        }
    ];
}

export function getTotalColumns (t){
    return [
        {
            name: t('billing.notifications.tableHeader.enterprise'),
            selector: row => row.parentEntityName,
            sortable: true,
        },
        {
            name: t('billing.notifications.tableHeader.entity'),
            selector: row => row.entityName,
            sortable: true,
        },
        {
            name: t('billing.notifications.tableHeader.type'),
            selector: row => row.type,
            sortable: true,
        },
        {
            name: t('billing.notifications.tableHeader.qty'),
            selector: row => row.counter,
            sortable: true,
            right: true,
        },
        {
            name: t('billing.notifications.tableHeader.limit'),
            selector: row => row.limit,
            sortable: true,
            right: true,
        },
        {
            name: t('billing.notifications.tableHeader.diff'),
            selector: row => row.difference,
            sortable: true,
            right: true,
        },
        {
            name: t('billing.notifications.tableHeader.price'),
            selector: row => row.price,
            sortable: true,
            right: true,
        },
        {
            name: t('billing.notifications.tableHeader.value'),
            selector: row => row.value,
            sortable: true,
            sortFunction: (a, b) => a.rawValue - b.rawValue,
            right: true,
        }
    ];
}