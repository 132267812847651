import {getTotalColumns} from "../config";
import FilterableTabContainer from "./FilterableTabContainer";
import {useTranslation} from "react-i18next";

const TotalsTabContainer = ({data}) => {
    const {t} = useTranslation();
    return (
        <FilterableTabContainer
            columns={getTotalColumns(t)}
            data={data}
            filterFields={['parentEntityName', 'entityName', 'type']}
            showTotalCost={true}
        />
    );
}

export default TotalsTabContainer;