import {Typography} from '@mui/material';
import MainLayout from "../components/MainLayout";

const TerminalsPage = () => {

    return (
        <MainLayout>
            <div>
                <Typography>Terminals Content</Typography>
            </div>
        </MainLayout>
    );
}

export default TerminalsPage;