import React, {useState, useEffect} from 'react'
import {Box, Button, Tab, Tabs, TextField, MenuItem, Menu} from "@mui/material";
import BillingService from "../../../services/BillingService";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Loading from "../../Loading";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TotalsConfig from "./TotalsConfig";
import SMSTabContainer from "./tabs/SMSTabContainer";
import VoiceTabContainer from "./tabs/VoiceTabContainer";
import TotalsTabContainer from "./tabs/TotalsTabContainer";
import {useTranslation} from "react-i18next";
import ExportService from "../../../services/ExportService";
import {formatCurrency} from "../../../utils/CurrencyUtil";
import '../../../styles/datepicker.css'

const BillingNotificationAuditContainer = () => {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const [voiceData, setVoiceData] = useState([]);
    const [smsData, setSmsData] = useState([]);
    const [combinedData, setCombinedData] = useState([]);
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [voicePrice, setVoicePrice] = useState(0.1);
    const [smsPrice, setSmsPrice] = useState(0.07);
    const [voiceLimit, setVoiceLimit] = useState(0);
    const [smsLimit, setSmsLimit] = useState(300);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChangeTab = (event, newTab) => {
        setActiveTab(newTab);
    };

    const fetchNotificationData = async (startDate, endDate) => {
        try {
            setLoading(true)
            const voiceAudit = await BillingService.fetchVoiceAudit(startDate, endDate);
            const smsAudit = await BillingService.fetchSMSAudit(startDate, endDate);
            const voiceDataWithTypes = voiceAudit.map(item => ({ ...item, type: 'VOICE' }));
            const smsDataWithTypes = smsAudit.map(item => ({ ...item, type: 'SMS' }));

            setSmsData(smsDataWithTypes);
            setVoiceData(voiceDataWithTypes);
            combineData(voiceDataWithTypes, smsDataWithTypes);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching voice data:', error);
        }
    };

    useEffect(() => {
        const start = startDate.toLocaleDateString('en-CA').split('T')[0];
        const end = endDate.toLocaleDateString('en-CA').split('T')[0];
        fetchNotificationData(start, end);
        // eslint-disable-next-line
    }, [startDate, endDate]);

    const handleFilterClick = () => {
        const start = startDate.toLocaleDateString('en-CA').split('T')[0];
        const end = endDate.toLocaleDateString('en-CA').split('T')[0];
        fetchNotificationData(start, end);
    };

    const combineData = (voiceData, smsData) => {
        const combined = [...voiceData, ...smsData];
        setCombinedData(combined);
        applyTotalFilters(combined);
    };

    const applyTotalFilters = (data) => {
        const updatedData = data.map(item => {
            const isVoice = item.type === 'VOICE';
            const price = isVoice ? voicePrice : smsPrice;
            const limit = isVoice ? voiceLimit : smsLimit;
            const difference = item.counter > limit ? item.counter - limit : 0;
            const value = difference * price;

            return {
                ...item,
                rawValue: value,
                price: formatCurrency(price),
                limit, difference,
                value: formatCurrency(value)
            };
        });
        setCombinedData(updatedData);
    };

    const handleExportClick = (format) => {
        let dataToExport = [];
        let fileName = '';

        switch (activeTab) {
            case 0:
                dataToExport = smsData;
                fileName = 'sms';
                break;
            case 1:
                dataToExport = voiceData;
                fileName = 'voice';
                break;
            case 2:
                dataToExport = combinedData;
                fileName = 'voice_and_sms_totals';
                break;
            default:
                return;
        }

        if (format === 'xlsx') {
            ExportService.exportToExcel(dataToExport, fileName)
        } else if (format === 'csv') {
            ExportService.exportToCSV(dataToExport, fileName)
        }

        setAnchorEl(null);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, margin: 1, alignItems: 'center'}}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        customInput={<TextField label={t('generic.inputs.startDate')}/>}
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd"
                        customInput={<TextField label={t('generic.inputs.endDate')} />}
                    />
                    <Button variant="contained" color="primary" onClick={handleFilterClick}>
                        {t('generic.filter')}
                    </Button>
                    <Button variant="contained" onClick={handleMenuClick}  color="success" aria-label="export"
                            aria-controls="export-menu" aria-haspopup="true" endIcon={<FileDownloadIcon />}>
                        {t('generic.export')}
                    </Button>
                    <Menu
                        id="export-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => handleExportClick('xlsx')}>XLSX</MenuItem>
                        <MenuItem onClick={() => handleExportClick('csv')}>CSV</MenuItem>
                        </Menu>
                </Box>
            </Box>

                {activeTab === 2 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <TotalsConfig
                        voicePrice={voicePrice}
                        setVoicePrice={setVoicePrice}
                        smsPrice={smsPrice}
                        setSmsPrice={setSmsPrice}
                        voiceLimit={voiceLimit}
                        setVoiceLimit={setVoiceLimit}
                        smsLimit={smsLimit}
                        setSmsLimit={setSmsLimit}
                        applyTotalFilters={() => applyTotalFilters(combinedData)}/>
                    </Box>

                )}

            <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: '80%' }}>
                <Tabs value={activeTab} onChange={handleChangeTab} aria-label="billing tabs">
                    <Tab label={t('billing.notifications.tabs.sms')} />
                    <Tab label={t('billing.notifications.tabs.voice')} />
                    <Tab label={t('billing.notifications.tabs.totals')} />
                </Tabs>
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <Box sx={{ maxWidth: '80%' }}>
                    <TabPanel value={activeTab} index={0}>
                        <SMSTabContainer data={smsData}/>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <VoiceTabContainer data={voiceData}/>
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <TotalsTabContainer data={combinedData} />
                    </TabPanel>
                </Box>
            )}

        </Box>
    );

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
}

export default BillingNotificationAuditContainer;